import { useState } from 'react'
import { VStack, Input, Button, Link, Text } from '@chakra-ui/react'

import { FormControl } from '@/components/FormControl'

import { inputStyle, linkStyle } from '@/features/auth/constants/styles'

import { t } from '@/lib/helpers'
import { getAuthenticityToken } from '@/lib/api'

type Props = {
  backupable: boolean
  formActionPath: string
  backupMode: boolean
}

function SignInTwoFactorForm({
  formActionPath,
  backupMode: initialBackupMode,
}: Props): React.ReactElement {
  const authenticityToken = getAuthenticityToken()
  const [backupMode, setBackupMode] = useState(initialBackupMode || false)

  return (
    <VStack
      spacing="4"
      sx={{
        alignItems: 'stretch',
      }}
    >
      <form
        id="new_user"
        action={formActionPath}
        acceptCharset="UTF-8"
        method="post"
      >
        <VStack
          spacing="4"
          sx={{
            alignItems: 'stretch',
          }}
        >
          <FormControl
            isRequired
            label={
              backupMode ? 'รหัสสำรอง' : 'รหัสที่ได้จากแอปพลิเคชันยืนยันตัวตน'
            }
          >
            <Input
              isRequired
              id="user_otp_attempt"
              type="text"
              autoComplete="off"
              autoFocus
              name="user[otp_attempt]"
              placeholder={
                backupMode
                  ? 'โปรดกรอกรหัสสำรอง'
                  : 'โปรดกรอกรหัสที่ได้จากแอปพลิเคชันยืนยันตัวตน'
              }
              sx={inputStyle}
            />
          </FormControl>

          <Input
            value={authenticityToken}
            type="hidden"
            name="authenticity_token"
          />

          <Input
            value={backupMode.toString()}
            type="hidden"
            name="user[backup_mode]"
          />

          <Button
            type="submit"
            name="commit"
            size="xl"
            value={t('devise.sessions.new.sign_in')}
            data-disable-with={t('devise.sessions.new.sign_in')}
          >
            {t('devise.sessions.new.sign_in')}
          </Button>
        </VStack>
      </form>

      {backupMode ? (
        <VStack
          spacing="1"
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Text>ไม่มีรหัสสำรอง?</Text>
          <Link
            href="#"
            sx={linkStyle}
            onClick={() => setBackupMode((state) => !state)}
          >
            กรอกรหัสที่ได้จากแอปพลิเคชันยืนยันตัวตน
          </Link>
          <Text>
            ถ้าคุณทำรหัสสำรองหาย,&nbsp;
            <Link
              sx={linkStyle}
              onClick={() => {
                window.$crisp && window.$crisp.push(['do', 'chat:open'])
              }}
            >
              ติดต่อทีมงาน
            </Link>
          </Text>
        </VStack>
      ) : (
        <VStack
          spacing="1"
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Text>หากไม่มีโทรศัพท์ในตอนนี้ ?</Text>
          <Link
            href="#"
            sx={linkStyle}
            onClick={() => setBackupMode((state) => !state)}
          >
            กรอกรหัสสำรอง
          </Link>
        </VStack>
      )}
    </VStack>
  )
}

export default SignInTwoFactorForm
