import { ComponentType, Dispatch, SetStateAction, useState } from 'react'
import { useDisclosure, UseDisclosureReturn, useToast } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { get } from 'lodash'

import { createCtx, formatDateTime, t } from '@/lib/helpers'
import { ERROR_TOAST_CONFIG } from '@/lib/constants'

import { priceConfig } from '../config'
import { withPage } from '@/features/layout'
import { RawCreditCard } from '../types'
import { CurrentPackage, PackageNameInUse, Period } from '@/features/types'

type PackageContextValue = {
  unsubscribeDisclosure: UseDisclosureReturn
  resubscribeDisclosure: UseDisclosureReturn
  upgradeFreeDisclosure: UseDisclosureReturn
  upgradeType1Disclosure: UseDisclosureReturn
  upgradeType2Disclosure: UseDisclosureReturn
  downgradeType1Disclosure: UseDisclosureReturn
  downgradeType2Disclosure: UseDisclosureReturn
  downgradeType31Disclosure: UseDisclosureReturn
  downgradeType32Disclosure: UseDisclosureReturn
  downgradeType4Disclosure: UseDisclosureReturn
  currentPackage: CurrentPackage
  expiry: string | null
  period: Period
  setPeriod: Dispatch<SetStateAction<Period>>
  plan: PackageNameInUse | null
  setPlan: Dispatch<SetStateAction<PackageNameInUse | null>>
  onCheckout: () => void
  cancelAtPeriodEnd: boolean
  willDowngradeToPackage: CurrentPackage['will_be_downgraded_to_package_name']
  resetOn?: string
}

export type PackagePageProps = {
  cards: RawCreditCard[]
  subscriptionCard: RawCreditCard
  currentPackage: CurrentPackage
  hadSubscribed: boolean
  hadRenewal: boolean
  resetOn?: string
}

const packageStoreContext = createCtx<PackageContextValue>()
const [, Provider] = packageStoreContext
export const [usePackageStore] = packageStoreContext

export const withPackagePage = <P extends object>(
  Component: ComponentType<P>,
) =>
  withPage((props: PackagePageProps & P) => {
    const { currentPackage, hadRenewal, resetOn } = props

    return (
      <PackageProvider
        currentPackage={currentPackage}
        hadRenewal={hadRenewal}
        resetOn={resetOn}
      >
        <Component {...props} />
      </PackageProvider>
    )
  })

type PackageProviderProps = {
  children: React.ReactNode
  currentPackage: CurrentPackage
  hadRenewal: boolean
  resetOn?: string
}

export const PackageProvider = (props: PackageProviderProps) => {
  const { children, currentPackage, hadRenewal, resetOn } = props

  const { t: tPackages } = useTranslation('packages')
  const [period, setPeriod] = useState<Period>(
    get(currentPackage, 'recurring', 'month'),
  )
  const [plan, setPlan] = useState<PackageNameInUse | null>(null)

  const unsubscribeDisclosure = useDisclosure()
  const resubscribeDisclosure = useDisclosure()

  const upgradeFreeDisclosure = useDisclosure()
  const upgradeType1Disclosure = useDisclosure()
  const upgradeType2Disclosure = useDisclosure()

  const downgradeType1Disclosure = useDisclosure()
  const downgradeType2Disclosure = useDisclosure()
  const downgradeType31Disclosure = useDisclosure()
  const downgradeType32Disclosure = useDisclosure()
  const downgradeType4Disclosure = useDisclosure()

  const cancelAtPeriodEnd = get(currentPackage, 'cancel_at_period_end')
  const willDowngradeToPackage = get(
    currentPackage,
    'will_be_downgraded_to_package_name',
  )

  const toast = useToast()

  const packageContextValue = {
    unsubscribeDisclosure,
    resubscribeDisclosure,
    upgradeFreeDisclosure,
    upgradeType1Disclosure,
    upgradeType2Disclosure,
    downgradeType1Disclosure,
    downgradeType2Disclosure,
    downgradeType31Disclosure,
    downgradeType32Disclosure,
    downgradeType4Disclosure,
    currentPackage,
    expiry: formatDateTime(get(currentPackage, 'expiry'), 'DD MMM YYYY'),
    period,
    setPeriod,
    plan,
    setPlan,
    onCheckout: () => {
      if (
        get(currentPackage, 'name') === 'free' ||
        currentPackage.subscription_token
      ) {
        if (plan && !hadRenewal) {
          window.location.href = `/checkout?package=${priceConfig[plan].token}&recurring=${period}`
        } else {
          toast({
            ...ERROR_TOAST_CONFIG,
            description: <Trans>{tPackages('schedulePackage')}</Trans>,
          })
        }
      } else {
        toast({
          ...ERROR_TOAST_CONFIG,
          description: <Trans>{tPackages('manualPaymentPackage')}</Trans>,
        })
      }
    },
    cancelAtPeriodEnd,
    willDowngradeToPackage,
    resetOn,
  }

  return <Provider value={packageContextValue}>{children}</Provider>
}
