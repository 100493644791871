import { get } from 'lodash'
import { Box, Link, Text } from '@chakra-ui/react'

import { PackageBanner, EnterpriseBanner } from '@/features/payments/components'
import { TabNavigation } from '@/components/shared'

import { PricingTableContainer } from '@/features/payments/containers'
import { ReferralBanner } from '@/features/referral/ReferralBanner'

import {
  withPackagePage,
  PackagePageProps,
  UpdatePaymentProvider,
} from '@/features/payments/stores'
import { t } from '@/lib/helpers'

import { PAYMENT_LAYOUT_TABS } from '../../constants'
import { HELPDESK_LINK } from '@/features/domains/constants'

function PackagePage(props: PackagePageProps) {
  const { currentPackage, hadSubscribed, cards, subscriptionCard } = props

  return (
    <Box layerStyle="container">
      <Box layerStyle="mainContent">
        <Box layerStyle="sectionHeader">
          {t('views.features.packages.title')}
        </Box>
        <TabNavigation routes={PAYMENT_LAYOUT_TABS} />

        <UpdatePaymentProvider
          cards={cards}
          subscriptionCard={subscriptionCard}
        >
          <PackageBanner />
        </UpdatePaymentProvider>
      </Box>

      <Box layerStyle="mainContent" sx={{ mt: 4, px: 4, pb: '80px' }}>
        <PricingTableContainer
          currentPlan={{
            plan: get(currentPackage, 'name'),
            period: get(currentPackage, 'recurring'),
          }}
          hadSubscribed={hadSubscribed}
        />

        <Box
          sx={{
            mt: 2,
            py: 4,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReferralBanner />
        </Box>

        <Box mt="56px">
          <EnterpriseBanner />
        </Box>

        <Text sx={{ mt: '10', color: 'gray.600' }}>
          {t('views.features.packages.more_information.title')} &nbsp;
          <Link
            isExternal
            href={HELPDESK_LINK.COOKIE_PACKAGES}
            sx={{
              color: 'brand.400',
              fontWeight: '700',
              textDecoration: 'underline',
            }}
          >
            {t('views.features.packages.more_information.link')}
          </Link>
        </Text>
      </Box>
    </Box>
  )
}

export default withPackagePage(PackagePage)
